import { DocumentReference } from '@angular/fire/compat/firestore';
import { PlaceGroupPlatform } from '@index/enums';
import firebase from 'firebase/compat';

import { Location } from './location';

export interface PlaceBaseInfo {
  title: string;
  description?: string;
  address: Location;
  images?: string[];
  deniedReview?: boolean;
}

export interface Contact {
  email?: string;
  phone?: string;
  website?: string;
}

export enum PlaceType {
  PickleBall = 'Pickleball',
}

export interface Place extends PlaceBaseInfo, Contact {
  id?: string;
  originalRendition?: string;
  lastUpdatedBy: string;
  reviewAccepted: boolean;
  pendingChanges?: any;
  pickleballMetadata?: PickleballCourt;
}

export enum PickleballLineTypes {
  Temporary = 'Temporary',
  Permanent = 'Permanent',
}

export enum PickleballNetTypes {
  Temporary = 'Portable Provided',
  Permanent = 'Permanent',
  BringYourOwn = 'Bring Your Own',
}

export interface PickleballCourt {
  indoorCourts: number;
  outdoorCourts: number;
  lines: PickleballLineTypes;
  nets: PickleballNetTypes;
  proShop: boolean;
  lights: boolean;
  restroom: boolean;
  scheduleDetails?: string;
  access?: string;
  feeDetails?: string;
}

export interface PlaceFollower {
  created: firebase.firestore.FieldValue;
  placeId: string;
  placeRef: DocumentReference<Place>;
  userId: string;
}

export interface PlaceGroup {
  platform: PlaceGroupPlatform;
  otherPlatform?: string;
  link: string;
  description: string;
  ownerContact: {
    email?: string;
    phone?: string;
  };
}
